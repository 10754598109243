import { Controller } from "stimulus"
import { Emojis } from "src/emojis"

export default class extends Controller {
  static targets = [ "togglePicker", 
                     "emojisPicker", 
                     "emojisList",
                     "input", 
                     "submitButton" ]

  connect() {
  }

  openPicker(event) {
    this.emojisPickerTarget.classList.remove("hidden");
    this.populatePicker();
  }

  closePicker(event) {
    this.emojisPickerTarget.classList.add("hidden");
    this.emojisListTarget.innerHTML = "";
  }

  clickOutsideClosePicker(event) {
    var toggle_picker = event.target.closest(".toggle-picker");
    if ((this.emojisPickerTarget.contains(event.target) === false) && !toggle_picker) {
      this.emojisPickerTarget.classList.add("hidden");
      this.emojisListTarget.innerHTML = "";
    }
  }

  escClosePicker(event) {
    if (event.key == "Escape") {
      this.emojisPickerTarget.classList.add("hidden");
      this.emojisListTarget.innerHTML = "";
    }
  }

  create(event) {
    var emoji_element = event.target;
    var emoji = emoji_element.dataset.emoji;
    this.inputTarget.value = emoji;
    this.submitButtonTarget.click();

    // Update favorite emojis in localstorage
    var favs_array = [];
    var anonymous_emojis = []
    favs_array.push(`${emoji}`);
    var current_favs_array = JSON.parse(localStorage.getItem("favorite_emojis"));
    if (current_favs_array) {
      if (current_favs_array.includes(`${emoji}`)) {
        var favs_array = current_favs_array;
      } else {
        var combined_array = current_favs_array.concat(favs_array);
        var favs_array = combined_array;
      }
    }
    favs_array.sort(function(x,y){ return x == emoji ? -1 : y == emoji ? 1 : 0; });
    var favs_string = JSON.stringify(favs_array);
    localStorage.setItem("favorite_emojis", favs_string);
  }

  scrollToCategory(event) {
    var category_link = event.target.closest(".emoji-category-link");
    var category_name = category_link.dataset.category;
    var container = this.emojisListTarget;
    var category_header = container.querySelector("h4[data-category='"+category_name+"']");
    category_header.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }

  populatePicker() {
    var container = this.emojisListTarget;
    const all = Emojis.emojis;

    // Populate elements for all emojis
    all.forEach((emoji) => {
      var el = document.createElement('div');
      el.classList.add("emoji","flex","flex-col","items-center","justify-center","w-8","h-8","mx-0.5","text-2xl","cursor-pointer","rounded-lg","hover:bg-gray-100");
      el.dataset.emoji = emoji.emoji;
      el.dataset.description = emoji.description;
      el.dataset.category = emoji.category;
      el.dataset.aliases = emoji.aliases;
      el.dataset.tags = emoji.tags;
      el.innerHTML = emoji.emoji;
      el.dataset.action = "click->reactions#create";
      container.appendChild(el);
    });

    // Insert category headers
    var header_classes = "block w-full pt-4 mb-2 ml-2 text-gray-300 text-sm font-medium uppercase tracking-wide";
    const category_names = [...new Set(all.map(item => item.category))][0];
    category_names.forEach((category_name) => {
      var first_emoji = container.querySelector("div[data-category='"+category_name+"']");
      first_emoji.insertAdjacentHTML("beforebegin", `<h4 data-category='${category_name}' class='${header_classes}'>${category_name}</h4>`);
    });

    // If favorites are present in localstorage, populate those
    var fav_emojis = localStorage.getItem("favorite_emojis");
    if (fav_emojis) {
      var fav_emojis_array = JSON.parse(fav_emojis);
      fav_emojis_array.forEach((emoji) => {
        var emoji_el = container.querySelector("div[data-emoji='"+emoji+"']");
        var cloned_emoji_el = emoji_el.cloneNode(true);
        container.insertAdjacentElement('afterbegin', cloned_emoji_el);
      });
      container.insertAdjacentHTML("afterbegin", `<h4 data-category='favorites' class='${header_classes}'>Favorites</h4>`);
    }
  }

  createReaction(event) {
    var emoji = event.target.dataset.emoji
    var messageId = event.target.dataset.messageId

    if (event.target.dataset.method === 'delete') {
      var emoji_array = JSON.parse(localStorage.getItem(messageId))
      if (emoji_array === null || emoji_array === undefined || !emoji_array.includes(`${emoji}`)) {
        event.preventDefault();

        console.log('hghj')
        var params = new URLSearchParams({
          user_id: null,
          emoji: emoji,
          from_js: true
        })

        fetch("/messages/" + messageId + `/reactions?${params}`, {
          method: "POST",
          headers: {
            'Content-Type': 'application/js'
          }
        }).then(function (result) {
          result.text().then(function (val) {
            return eval(val);
          });
        });
      }
    }
}
}