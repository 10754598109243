import {DirectUpload} from "@rails/activestorage";
import UploadError from "./upload_error";

export default class RailsDirectUpload {
  constructor(file, url, mediaField, submitButtonBottom, recordingEndTime, browserName, saveButton, scheduleButton, editRecordingButton, messageForm) {
    console.log("inside DirectUploadController")

    this.uploader = new DirectUpload(file, url, this);
    this.mediaField = mediaField;
    this.submitButtonBottom = submitButtonBottom;
    this.saveButton = saveButton;
    this.scheduleButton = scheduleButton;
    this.editRecordingButton = editRecordingButton;
    this.messageForm = messageForm
    this.submitButtonBottomContainer = this.messageForm.querySelector("#submitBtnBottomContainer");
    this.recordingEndTime = recordingEndTime;
    this.browserName = browserName;

    window.addEventListener('beforeunload', this.preventPageRefresh);

    this.customProgressElements = document.querySelectorAll('.progressbar');
    this.customProgressWrapElements = document.querySelectorAll('.progressbar-wrap');
    this.disableBtn = document.querySelector("#discardRecordingBtn");
    this.canShowSubmit = false;
  }

  upload(file) {
    this.uploader.create((error, blob) => {
      var additionalInfoHash = {
        browserName: this.browserName,
        errorName: "DirectUploadError"
      }

      if(error) {
        console.log(error)
        // Handle the error.
        this.showHideElementAfterUpload();
        let uploadErrors = this.messageForm.querySelector("#upload-errors");
        let downloadBtn = this.messageForm.querySelector("#downloadBtn");
        let submitBtnTop = this.messageForm.querySelector("#submitBtnTop");

        if(uploadErrors){

          if(navigator.onLine === false) {
            let error_info = [];
            error_info.push(this.browserName, 'UserOffline');
            localStorage.setItem("error-info", JSON.stringify(error_info));
          }

          // show upload error message
          uploadErrors.classList.remove("hidden");
          uploadErrors.innerHTML = "Oops, a network error prevented your recording from being fully uploaded.  Our team has been alerted.  Please use the 'Download recording' link to download your recording then try uploading manually or re-recording.";
        }

        // Hide message creation buttons if we couldn't upload the recording
        if (submitBtnTop) {
          submitBtnTop.classList.add("hidden");
        }
        if (this.submitButtonBottomContainer) {
          this.submitButtonBottomContainer.classList.add("hidden");
        }

        if(downloadBtn) {
          downloadBtn.classList.remove("hidden") // show the download button
        }

        if(this.disableBtn) {
          this.disableBtn.classList.remove("hidden"); // show the disable button
        }

        this.shareIncompleteMultipartBlob();

        window.uploadErrorInitializer.assignError(error, additionalInfoHash);
        window.uploadErrorInitializer.sendError();

        if(window.appsignal !== undefined) {
          var span = window.appsignal.createSpan();

          span.setTags({ tag: "Upload error" }).setError(new Error("Direct upload failed while uploading file"));
          window.appsignal.send(span);
        }
      }
      else {
        // Add the <input type="hidden"> with the signature.
        var hiddenField = document.createElement('input')
        hiddenField.setAttribute('type', 'hidden');
        hiddenField.setAttribute("name", "message[media]");
        hiddenField.setAttribute("id", "media-signed-id");
        hiddenField.setAttribute('value', blob.signed_id);

        var hiddenContentType = document.createElement('input')
        hiddenContentType.setAttribute('type', 'hidden');
        hiddenContentType.setAttribute("name", "message[content_type]");
        hiddenContentType.setAttribute("id", "media-content-type");
        hiddenContentType.setAttribute('value', blob.content_type);

        var hiddenFieldType = document.createElement('input')
        hiddenFieldType.setAttribute('type', 'hidden');
        hiddenFieldType.setAttribute("name", "message[recorded_duration]");
        hiddenFieldType.setAttribute("id", "media-recording-end-time");
        hiddenFieldType.setAttribute('value', this.recordingEndTime);

        this.mediaField.insertAdjacentElement("afterend", hiddenField);
        this.mediaField.insertAdjacentElement("afterend", hiddenFieldType);
        this.mediaField.insertAdjacentElement("afterend", hiddenContentType);

        // Show the submit button in the top controls
        if (this.messageForm !== null && this.messageForm.getAttribute('data-message--navigation-changed') === "true") {
          this.messageForm.querySelector("#submitBtnTop").classList.remove("hidden");
          var tour_post_message = this.messageForm.querySelector("#tour-post-message");
          if (tour_post_message) {
            tour_post_message.classList.remove("z-0");
            tour_post_message.classList.add("translate-y-8", "opacity-100", "z-100");
          }
        }

        // Enable submit button
        if(this.submitButtonBottom && blob.signed_id) {
          this.submitButtonBottom.disabled = false;
          this.submitButtonBottom.classList.remove("btn-disabled");
          this.submitButtonBottom.classList.remove("hidden");
          this.submitButtonBottomContainer.classList.remove("hidden");
          if (this.saveButton)
            this.saveButton.classList.remove("hidden");// Reveal the save draft button in the control bar
          if (this.scheduleButton)
            this.scheduleButton.classList.remove("hidden");// Reveal the schedule button in the control bar

          if (this.editRecordingButton)
            this.editRecordingButton.classList.remove("hidden"); // Reveal the edit recording button in the control bar
        } else {
          let uploadErrors = document.getElementById("upload-errors");
          let downloadBtn = document.getElementById("downloadBtn");

          if(downloadBtn) {
            downloadBtn.classList.remove("hidden") // show the download button
          }

          // show upload error message
          uploadErrors.classList.remove("hidden");
          uploadErrors.innerHTML = "Oops, a network error prevented your recording from being fully uploaded.  Our team has been alerted.  Please use the 'Download recording' link to download your recording then try uploading manually or re-recording.";

          window.uploadErrorInitializer.assignError(error, additionalInfoHash);
          window.uploadErrorInitializer.sendError();

          if(window.appsignal !== undefined) {
            var span = window.appsignal.createSpan();

            span.setTags({ tag: "Upload error" }).setError(new Error("Direct upload failed after uploading file"));
            window.appsignal.send(span);
          }
        }



        if(this.canShowSubmit) {
          this.showHideElementAfterUpload();
        }

        this.removeMultipartElements();
      }
    });
  }

  directUploadWillStoreFileWithXHR(request) {
    this.xhrRequest = request;
    this.disableBtn.classList.remove("hidden"); // show the download button

    // Update element based on the progress
    request.upload.addEventListener("progress",
        event => this.directUploadDidProgress(event, this.submitButtonBottom))

    // Abort request based on listening to button click event
    if(this.disableBtn) {
      this.disableBtn.addEventListener("click", (event) => {

        setTimeout(() => {
          if(this.disableBtn.dataset.discardRecording === "true") {
            this.xhrRequest.abort();
            // Reset element once request is aborted
            this.showHideElementAfterUpload();

          }
        }, 1000);
      })
    }
  }

  preventPageRefresh(event) {
    event.preventDefault();
    event.returnValue = '';
    return "Video upload is in progress";
  }

  directUploadDidProgress(event) {
    let percent = ((event.loaded / event.total) * 100).toFixed(1);

    if(this.customProgressElements){
      // // Show progress wrapper when upload is in progress
      // Array.from(this.customProgressWrapElements).forEach((customProgressWrapElement) => {
      //   customProgressWrapElement.classList.remove("hidden")
      // })

      Array.from(this.customProgressElements).forEach((customProgressElement) => {
        console.log('output percentage:');
        console.log(percent);
        if(percent === "100.0") {
          this.canShowSubmit = true;
        } else {
          if(!customProgressElement.classList.contains("hidden")){
            customProgressElement.classList.remove("hidden")
          }
        }

        // Update width of the progressbar based on the upload percentage
        customProgressElement.style.width = `${percent}%`;
      })
    }
  }

  showHideElementAfterUpload() {
    // Remove before unload event to allow users to close the browser window
    window.removeEventListener('beforeunload', this.preventPageRefresh)

    // Hide progress bar once request is completed
    // Array.from(this.customProgressElements).forEach((customProgressElement) => {
    //   customProgressElement.classList.add("hidden")
    //   Array.from(this.customProgressWrapElements).forEach((customProgressWrapElement) => {
    //     customProgressWrapElement.classList.add("hidden")
    //   })
    // })

    this.messageForm.querySelector("#progressBar").classList.add("hidden"); // Hide the progress bar in the top controls

    // Close modal only when it is open
    let uploadCloseModal = this.messageForm.querySelector("#video-upload-close-modal");
    let modalBackground = this.messageForm.querySelector("#modal-background");
    if(modalBackground) {
      uploadCloseModal.click()
    }
  }

  removeMultipartElements() {
    // Remove multipart related elements
    var mediaKey = this.messageForm.querySelector("#media-key")
    var mediaFile = this.messageForm.querySelector("#media-filename")

    if(mediaKey) {
      mediaKey.remove();
    }
    if(mediaFile) {
      mediaFile.remove();
    }
  }

  shareIncompleteMultipartBlob() {
    var incompleteMediaKey = this.messageForm.querySelector("#media-incomplete-multipart-key");
    var sessionUserId = this.messageForm.querySelector("#session-user-id");
    var sessionUserIdValue, incompleteMediaKeyValue;

    if(sessionUserId)
      sessionUserIdValue = sessionUserId.value

    if(incompleteMediaKey)
      incompleteMediaKeyValue = incompleteMediaKey.value

    if(incompleteMediaKey) {
      var params = new URLSearchParams({
        incomplete_media_key: incompleteMediaKeyValue,
        user_id: sessionUserIdValue
      })
      fetch(`/accounts/${accountId}/mail_incomplete_multipart_recording?${params}`, {
        method: "GET",
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(response => response.json())
          .then(data => {
            console.log(data);
          })
          .catch(e => {
            console.log(e)

            if(window.appsignal !== undefined) window.appsignal.sendError(e);
          });
    }
  }
}