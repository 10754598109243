import { Controller } from "stimulus";
import UploadError from "./concerns/upload_error";
import screen_media_stream from "./media_streams/screen_media_stream";
import audio_media_stream from "./media_streams/audio_media_stream";
import camera_media_stream from "./media_streams/camera_media_stream";
import media_recorder from "./recorder/media_recorder";

var limitRecordingInterval;
window.multiPartUploadInitializer = window.createUploadPromise = undefined;

export default class extends Controller {
  static values = {
    pendingSeconds: String,
    freeAccount: String,
    nonAccountMember: String,
    maxMediaSize: Number,
    timeLeft: Number,
    recordingEnded: Boolean,
    recorderError: Object
  }

  static targets = ["messageUI",
    "controls",
    "messageTypeField",
    "messageTypeMenu",
    "tallVideoField",
    "kickoffRecordingPanel",
    "preview",
    "recording",
    "backgroundImage",
    "initiateDeviceSelectionBtn",
    "camMicSelectors",
    "selectAudioSource",
    "selectVideoSource",
    "selectCameraLayout",
    "troubleshootModal",
    "prerecordOverlay",
    "loadingIcon",
    "startCameraRecordBtn",
    "startScreenRecordBtn",
    "startMicRecordBtn",
    "countdown",
    "previewStreamPlayer",
    "screenRecordingInProgress",
    "screenRecordingInProgressMessage",
    "micRecordingInProgress",
    "screenCameraRecordingInProgress",
    "micRecordingInProgressMessage",
    "screenCameraRecordingInProgressMessage",
    "timer",
    "recordingIndicator",
    "recordingIndicatorIcon",
    "resetBtn",
    "stopBtn",
    "pauseBtn",
    "resumeBtn",
    "discardBtn",
    "preRecordPipBtn",
    "duringRecordPipBtn",
    "downloadBtn",
    "saveBtn",
    "scheduleBtn",
    "cancelBtn",
    "scheduledBooleanInput",
    "editTextBtn",
    "editRecordBtn",
    "mediaField",
    "textEditor",
    "submitButtonBottomContainer",
    "submitButtonBottom",
    "submitButtonTop",
    "submitButtonUploader",
    "progressBar",
    "activeMedia",
    "uploadError",
    "reachedLimitMessage",
    "notYouLink",
    "embedIframeField",
    "embedPlayerLinkField",
    "fileAttachmentField",
    "conversationLinkField",
    "mediaDevice"
  ]

  connect() {
    var screen_width = screen.width;
    var screen_height = screen.height;
    var screen_aspect_ratio = screen_width/screen_height;
    if (screen_aspect_ratio < 1) {
      this.tallVideoFieldTarget.value = true;
    }
    if (this.hasSubmitButtonBottomTarget) {
      this.submitButtonBottomContainerTarget.classList.add("hidden");
    }
    if (this.hasResumeBtnTarget) {
      this.submitButtonTopTarget.classList.add("hidden");
    }
    window.uploadErrorInitializer = new UploadError;
    window.uploadErrorInitializer.setItemInLocalStorage();

    if (this.hasMediaFieldTarget) {
      this.mediaFieldTarget.dataset.directUploadUrl = this.mediaFieldTarget.dataset.directUploadUrl + `?enable_hifi_recording=${this.mediaFieldTarget.dataset.enableHifiRecording}`
    }
  }

  initiateDeviceSelection() {
    this.camMicSelectorsTarget.classList.remove("hidden");
    var message_type = this.messageTypeFieldTarget.value;
    this.initiateDeviceSelectionBtnTarget.classList.add('hidden', 'sm:hidden');
    if (message_type === "screencamera" || message_type === "camera") {
      this.selectVideoSourceTarget.classList.remove('hidden');
    }
    this.selectAudioSourceTarget.classList.remove('hidden');
    this.resetBtnTarget.classList.remove('hidden');
    this.previewStream();
  }

  previewStream(){
    // Method will be called when mic/camera device toggled to initiate stream in preview before user clicks share your screen btn
    var cameraButton = this.mediaDeviceTarget.querySelector('div[data-message--kickoff-recording-target="cameraButton"]');
    var micButton = this.mediaDeviceTarget.querySelector('div[data-message--kickoff-recording-target="micButton"]');

    // Turn off/stop all active stream either if its activated by base message or thread message
    this.stop();
    // checking initiateDeviceSelection() called before. below codes have to executed only if user see camera or mic in selection device
    if(!this.initiateDeviceSelectionBtnTarget.classList.contains('sm:hidden')) return

    if(cameraButton.classList.contains('active'))
      camera_media_stream.call(this);
    else if(micButton.classList.contains('active'))
      audio_media_stream.call(this);
    else
      this.stop();
  }

  start_camera_preview() {
    this.previewStartedUI(); // UI updates when preview (camera or screen) has started
    this.previewCameraStartedUI(); // UI updates when preview of camera has started

    camera_media_stream.call(this);
  }// END: start_camera_preview()

  start_mic_preview() {
    this.previewStartedUI(); // UI updates when preview (camera or screen or mic) has started
    this.previewMicStartedUI(); // UI updates when preview of mic has started

    audio_media_stream.call(this);
  }// END: start_mic_preview()

  start_screen_preview() {
    this.previewStartedUI(); // UI updates when preview (camera or screen) has started
    this.previewScreenStartedUI(); // UI updates when preview of screen has started

    screen_media_stream.call(this);
  }// END: start_camera_preview()

  start_countdown() {
    var countdown_container = this.countdownTarget;
    this.startScreenRecordBtnTarget.classList.add("hidden"); // Hide "start recording screen" button
    this.startCameraRecordBtnTarget.classList.add("hidden"); // Hide "start recording camera" button
    this.startMicRecordBtnTarget.classList.add("hidden"); // Hide "start recording camera" button
    this.camMicSelectorsTarget.classList.add("hidden"); // Hide camere and mic selection
    countdown_container.classList.remove("hidden"); // Show countdown

    var countdownTimeleft = 2;
    var countdownTimer = setInterval(function(){
      if(countdownTimeleft <= 0){
        clearInterval(countdownTimer);
        countdown_container.querySelector("#start-record").click();
      } else {
        countdown_container.querySelector("#countdown-numbers").innerHTML = countdownTimeleft;
      }
      countdownTimeleft -= 1;
    }, 1000);
  }

  start_record() {
    this.recordingStartedUI();
    this.start_timer();
    this.limitRecording();

    media_recorder.call(this);
  }

  start_timer() {
    var timer_containers = this.timerTargets;
    timer_containers.forEach(target => target.classList.remove("hidden"));
    this.messageTypeMenuTarget.classList.add("hidden"); // Hide message type menu when countdown starts

    var countFromDate = new Date();
    var minutes, seconds, prev_distance, distance, localRecordingLimit;
    localRecordingLimit = this.pendingSecondsValue;

    // Update the count down every 1 second
    window.timerInterval = setInterval(function() {
      
      // Get todays date and time
      var now = new Date().getTime();

      // Find the distance between now an the count down date
      distance = now - countFromDate.getTime();
      prev_distance = distance;

      // Time calculations for minutes and seconds
      minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      seconds = Math.floor((distance % (1000 * 60)) / 1000);

      // Add preceding zeros to seconds, if present
      var seconds_value = seconds.toString().padStart(2, '0');

      // Output the result in an element with id="demo"
      timer_containers.forEach((element) => {
        var timer_element = element.querySelector("#timer");
        timer_element.innerHTML = minutes + ":" + seconds_value;
      })
    }, 0);

    if(this.pauseBtnTargets) {
      this.pauseBtnTargets.forEach((element) => {
       element.addEventListener("click", function () {
          prev_distance = distance;

          if (window.timerInterval) clearInterval(window.timerInterval);
          if (limitRecordingInterval) {
            clearTimeout(limitRecordingInterval)

            this.pendingSecondsValue = parseInt(localRecordingLimit) - ((minutes * 60) + seconds);
          }

        }.bind(this))
      })
    }
    if(this.resumeBtnTargets) {
      this.resumeBtnTargets.forEach((element) => {
        element.addEventListener("click", function () {
          countFromDate = new Date();

          this.limitRecording();
          window.timerInterval = setInterval(function() {

            // Get todays date and time
            var now = new Date().getTime();

            // Find the distance between now an the count down date
            distance = now - countFromDate.getTime();

            distance = prev_distance + distance; if(prev_distance !== undefined)
                // Time calculations for minutes and seconds
              minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            seconds = Math.floor((distance % (1000 * 60)) / 1000);

            // Add preceding zeros to seconds, if present
            var seconds_value = seconds.toString().padStart(2, '0');

            // Output the result in an element with id="demo"
            timer_containers.forEach((element) => {
              var timer_element = element.querySelector("#timer");
              timer_element.innerHTML = minutes + ":" + seconds_value;
            })
          }, 0);
        }.bind(this))
      })
    }




  }

  stop() {
    this.messageUITarget.querySelector("#lowAudioLevelAlert").classList.add("hidden");
    this.messageUITarget.querySelector("#recLowAudioLevelAlert").classList.add("hidden");

    // stop stream
    if (window.stream) {
      window.stream.getTracks().forEach(track => {
        track.stop();
      });
    }

    if(window.streamArr) {
      window.streamArr.forEach(stream => {
        stream.getTracks().forEach(track => {
          track.stop();
        });
      })

      window.streamArr = [];
    }

    if(document.pictureInPictureElement) {
      document.exitPictureInPicture();
    }

    this.clearConversationLink()
  }

  async discard_and_re_record() {
    this.recordingTarget.src = '';
    this.recordingTarget.classList.add("hidden"); // show the recording playback

    var newMessageForm = this.messageUITarget

    if(newMessageForm){
      newMessageForm.removeAttribute('data-message--navigation-changed')
      newMessageForm.setAttribute('data-message--navigation-changed', 'false')
    }

    if(this.hasDiscardBtnTarget){
      this.discardBtnTarget.setAttribute("data-discard-recording", "true")
      this.discardBtnTarget.classList.add("hidden");
      this.submitButtonBottomTarget.disable = true;
      this.submitButtonTopTarget.disable = true;
      this.submitButtonBottomTarget.classList.add("hidden");
      this.submitButtonTopTarget.classList.add("hidden");
      if (this.hasSaveBtnTarget) {
        this.saveBtnTarget.remove();
      }
    }

    let mediaSignedId = document.querySelector("#media-signed-id")
    if(mediaSignedId) {
      let data = {
        signed_id: mediaSignedId.value
      };

      await fetch("/transcoded_medias/delete_message_blob",{
        method: "DELETE",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      })
      .then(response => response.json())
      .then(data => {
        mediaSignedId.value = '';
        console.log('Success:', data);
        window.location.reload(true);
      })
      .catch((error) => {
        console.error('Error:', error);
        if(window.appsignal !== undefined) { var span = window.appsignal.createSpan();
          span.setParams({ account_id: accountId})
          span.setTags({
            tag: "Multipart Upload error"
          }).setError({name: "AbortPartUploadError", message: error});
          window.appsignal.send(span);};
      });
    }

    if(window.multiPartUploadInitializer && window.createUploadPromise) {
      window.multiPartUploadInitializer.discardUpload();
    }
  }

  previewStartedUI() {
    // The preview (camera, screen, or mic) has started...
    this.camMicSelectorsTarget.classList.remove("hidden"); // Show the camera and mic selectors overlay
    this.initiateDeviceSelectionBtnTarget.classList.add("hidden", "sm:hidden"); // Hide the "Select devices" button since we don't need to re-initiate that now that the preview has started.
    this.element.classList.remove("mt-8"); // Remove top margin from the form element because the controls bar will now provide that.
    this.kickoffRecordingPanelTarget.classList.add("hidden"); // Hide the Kickoff panel
    this.resetBtnTarget.classList.remove("hidden"); // show "reset" button
    if (this.hasCancelBtnTarget) {
      this.cancelBtnTarget.classList.add("hidden"); // hide the "cancel new message" button (user can use the reset button at this stage)
    }
    this.editTextBtnTarget.classList.remove("hidden"); // show "edit text" button
    this.previewTarget.classList.remove("hidden"); // show the preview container
    if (this.hasSaveBtnTarget) {
      this.saveBtnTarget.classList.add("hidden");
    }
    if (this.hasScheduleBtnTarget) {
      this.scheduleBtnTarget.classList.add("hidden");
    }

    if(document.querySelector('div[data-message--kickoff-recording-target="micButton"]').classList.contains('active')) {
      this.selectAudioSourceTarget.classList.remove("hidden"); // Show the mic selection + Audio meter
    }
  }

  previewCameraStartedUI() {
    this.prerecordOverlayTarget.classList.add("bg-white", "bg-opacity-60"); // Make the overlay background transparent so user can see the preview of their camera
    this.selectVideoSourceTarget.classList.remove("hidden"); // Show the camera selection dropdown
    this.previewStreamPlayerTarget.classList.remove("hidden"); // show the camera preview stream player
    if (this.hasBackgroundImageTarget) {
      this.backgroundImageTarget.classList.remove("hidden"); // show the background image button
    }
    var timeLimitInfo = this.previewTarget.querySelector(".time-limit-info");
    if (timeLimitInfo) {
      timeLimitInfo.classList.add("text-gray-700");
      var timeLimitInfoLink = timeLimitInfo.querySelector("a");
      if (timeLimitInfoLink) {
        timeLimitInfoLink.classList.add("text-white");
        timeLimitInfoLink.classList.remove("text-primary-600");
      }
    }
  }

  previewMicStartedUI() {
    this.selectAudioSourceTarget.classList.remove("hidden"); // Show the mic selection + Audio meter
    this.selectVideoSourceTarget.classList.add("hidden"); // hide the camera selection
    this.micRecordingInProgressTarget.classList.remove("hidden"); // show "screen recording in progress..." container
  }

  previewScreenStartedUI() {
    if (this.messageTypeFieldTarget.value == "screencamera") {
      this.selectVideoSourceTarget.classList.remove("hidden"); // Show the camera selection
      this.preRecordPipBtnTarget.classList.remove("hidden"); // Show the PIP status button
    }
    this.screenRecordingInProgressTarget.classList.remove("hidden"); // show "screen recording in progress..." container
  }

  screenRecordingStartedUI() {
    // show the "screen recording in progress..." content, but wait 3 seconds for the 3,2,1 timer to complete first.
    setTimeout(() => this.screenRecordingInProgressMessageTarget.classList.remove("opacity-0"), 3000)
  }

  micRecordingStartedUI() {
    // show the "screen recording in progress..." content, but wait 3 seconds for the 3,2,1 timer to complete first.
    setTimeout(() => this.micRecordingInProgressMessageTarget.classList.remove("opacity-0"), 3000)
  }

  recordingStartedUI() {
    const sUsrAg = navigator.userAgent.toLowerCase();
    let isSafari = false;
    if (sUsrAg.indexOf('safari') !== -1 && sUsrAg.indexOf('chrome') === -1) {
      isSafari = true;
    }

    this.element.classList.add("recording-in-progress");

    this.camMicSelectorsTarget.classList.add("hidden"); // Hide camere and mic selection
    this.prerecordOverlayTarget.classList.add("hidden"); // hide pre-recording overlay
    this.stopBtnTargets.forEach(target => target.classList.remove("hidden")); // show "stop" button
    this.stopBtnTarget.classList.add("recording"); // Add .recording class to the Stop button so we can change its color
    this.recordingIndicatorTargets.forEach(target => target.classList.remove("hidden")); // Show the recording indicator button
    this.resetBtnTarget.classList.add("hidden"); // hide "reset" button
    this.pauseBtnTargets.forEach(target => target.classList.remove("hidden")); // Show the pause button
    this.resumeBtnTargets.forEach(target => target.classList.add("hidden")) // Show the pause button
    this.preRecordPipBtnTarget.classList.add("hidden"); // Hide the camera button
    if (this.hasSaveBtnTarget) {
      this.saveBtnTarget.classList.add("hidden"); // Hide the save draft button (if it is shown)
    }
    if (this.hasScheduleBtnTarget) {
      this.scheduleBtnTarget.classList.add("hidden"); // Hide the schedule button (if it is shown)
    }
    if (this.hasNotYouLinkTarget) {
      this.notYouLinkTarget.classList.add("hidden"); // Hide the "Not you?" link on user's avatar, if present
    }
    if (this.hasBackgroundImageTarget) {
      this.backgroundImageTarget.classList.add("hidden"); // Hide the background image button
    }

    this.submitButtonBottomContainerTarget.classList.add("hidden");
    this.submitButtonTopTarget.classList.add("hidden");
    
    document.title = `Recording...`;
  }

  recordingFinishedUI() {
    // Now that the recording is finished...

    this.previewTarget.classList.add("hidden"); // hide the preview stream
    this.recordingTarget.classList.remove("hidden"); // show the recording playback

    // For some reason, recordings inside threads added this vjs-controls-disabled class, which prevents playback after finishing recording.
    // If that class is present, remove it so that user can playback their recording.
    var disabled_player = this.recordingTarget.querySelector(".vjs-controls-disabled");
    if (disabled_player) {
      disabled_player.classList.remove("vjs-controls-disabled");
    }

    this.stopBtnTargets.forEach(target => target.classList.add("hidden")); // hide the stop button
    this.timerTargets.forEach(target => target.classList.add("hidden")); // hide the timer
    this.progressBarTarget.classList.remove("hidden"); // show the progress bar
    this.stopBtnTarget.classList.remove("recording"); // remove the .recording class from the stop button
    this.recordingIndicatorTargets.forEach(target => target.classList.add("hidden")); // Hide the recording indicator button
    this.resumeBtnTargets.forEach(target => target.classList.add("hidden")); // Hide the resume button
    this.pauseBtnTargets.forEach(target => target.classList.add("hidden")); // Hide the pause button
    this.preRecordPipBtnTarget.classList.add("hidden"); // Hide the pip button
    this.duringRecordPipBtnTarget.classList.add("hidden"); // Hide the pip button
    this.downloadBtnTarget.classList.remove("hidden"); // Reveal the download button
    this.duringRecordPipBtnTarget.classList.add("hidden"); // Hide the pip button
    this.discardBtnTarget.classList.remove("hidden"); // Reveal the 'discard & re-record' button
    this.messageUITarget.querySelector("#recLowAudioLevelAlert").remove(); // removed the low audio level indicator
    this.messageUITarget.querySelector("#lowAudioLevelAlert").remove(); // removed the low audio level indicator
    document.title = 'Almost finished...';

    var new_message = this.messageUITarget
    new_message.scrollIntoView({behavior: "smooth", block: "start"});

    // From fixed message creation controls
    var fixedMessageControls = document.querySelector('#fixed-message-creation-controls');
    if (fixedMessageControls) fixedMessageControls.remove();
  }

  hideRecordingUI() {
    this.recordingTarget.classList.add("hidden"); // show the recording playback
  }

  reveal_create_button() {
    this.changeBtnText()
    
    if (!this.element.classList.contains("recording-in-progress")) {
      
      // Show the save draft button icon
      if (this.hasSaveBtnTarget) {
        this.saveBtnTarget.classList.remove("hidden");
      }

      // Show the schedule button icon
      if (this.hasScheduleBtnTarget) {
        this.scheduleBtnTarget.classList.remove("hidden");
      }

      this.submitButtonTopTarget.classList.remove("hidden"); // Unhide the top submit button
      this.submitButtonBottomContainerTarget.classList.remove("hidden"); // Unhide the bottom container
      this.submitButtonBottomTarget.classList.remove("hidden"); // Unhide the bottom submit button

      if (this.hasMessageTypeMenuTarget) {
        this.messageTypeMenuTarget.classList.add("truncated"); // Change the message type menu to truncated mode (shorter width)
      }
    }
  }

  hide_create_button() {
      this.submitButtonTopTarget.classList.add("hidden");
      this.submitButtonBottomContainerTarget.classList.add("hidden");
      this.submitButtonBottomTarget.classList.add("hidden");
  }

  preventDiscardAndSaveDraftAndEditRecordButtonClick() {
    this.discardBtnTarget.disabled = true;
    this.discardBtnTarget.setAttribute("data-prevent-execution", "true")
    if(this.hasEditRecordBtnTarget) {
      this.editRecordBtnTarget.dataset.preventFormSubmit = true; // setting data attribute to avoid multiple form submit when multiple request arrives
    }
    if (this.hasSaveBtnTarget) {
      this.saveBtnTarget.dataset.preventFormSubmit = true; // setting data attribute to avoid multiple form submit when multiple request arrives
    }
  }

  preventSubmitButtonClick(){
    this.submitButtonBottomTarget.disabled = true;
    this.submitButtonTopTarget.disabled = true;
  }

  mediaError(error) {
    if(error.name === "NotAllowedError" || error.name === "NotFoundError") {
      console.log("Media permission denied...")
    } else if (error.name === "NotReadableError" || error.name === "TrackStartError") {
      console.log("webcam or mic are already in use")
    } else if (error.name === "OverconstrainedError" || error.name === "ConstraintNotSatisfiedError") {
      console.log("constraints can not be satisfied by avb. devices")
    } else if (error.name === "NotAllowedError" || error.name === "PermissionDeniedError") {
      console.log("permission denied in browser")
    } else if (error.name === "TypeError") {
      console.log("empty constraints object")
    } else if (error.name === "AbortError") {
      console.log("User and browser both granted access to hardware device but some problem occurred which prevented the device from being used.")
    } else if (error.name === "OverconstrainedError") {
      console.log("Constraint property string value not meet")
    } else if (error.name === "SecurityError") {
      console.log("User media support is disabled")
    }

    var troubleshootModal = this.troubleshootModalTarget;
    var jsError = this.messageUITarget.querySelector("#troubleshootJsError");
    var browserNameElements = document.querySelectorAll(".troubleshootModalBrowserName");
    var osName = this.messageUITarget.querySelector("#troubleshootModalOsName");
    var winControl = this.messageUITarget.querySelector("#winControl");
    var macCommand = this.messageUITarget.querySelector("#macCommand");
    var browserName = this.detectBrowser();

    var additionalInfoHash = {
      "browserName": browserName
    }

    // Assign error values and send error
    window.uploadErrorInitializer.assignError(error, additionalInfoHash);
    window.uploadErrorInitializer.sendError();

    if(troubleshootModal) {
      troubleshootModal.click(); // open modal

      for (let browserNameElement of browserNameElements) {
        browserNameElement.innerText = browserName;
      }

      var os_name = this.detectOs();
      if(osName) osName.innerText = os_name;
      if(jsError) jsError.innerText = error.name;

      if (os_name === "Windows") {
        document.querySelector("#windows-instructions").classList.remove("hidden");
        document.querySelector("#mac-instructions").classList.add("hidden");
        if (winControl) {
          winControl.classList.remove("hidden");
          macCommand.classList.add("hidden");
        }
      } else {
        document.querySelector("#windows-instructions").classList.add("hidden");
        document.querySelector("#mac-instructions").classList.remove("hidden");
        if (macCommand) {
          winControl.classList.add("hidden");
          macCommand.classList.remove("hidden");
        }
      }
    }
  }

  detectBrowser() {
    var nAgent = navigator.userAgent;
    var browserName;
    if (nAgent.indexOf("Firefox")!== -1) browserName = "Firefox";
    if (nAgent.indexOf("Safari")!== -1) browserName = "Safari";
    if (nAgent.indexOf("Chrome")!== -1) browserName = "Chrome";
    if (nAgent.indexOf("MSIE")!== -1) browserName = "MSIE";
    if (nAgent.indexOf("Opera")!== -1) browserName = "Opera";

    return browserName;
  }

  detectOs() {
    var OSName;
    if (navigator.appVersion.indexOf("Win")!== -1) OSName = "Windows";
    if (navigator.appVersion.indexOf("Mac")!== -1) OSName = "MacOS";
    if (navigator.appVersion.indexOf("X11")!== -1) OSName = "UNIX";
    if (navigator.appVersion.indexOf("Linux")!== -1) OSName = "Linux";

    return OSName;
  }

  limitRecording() {
    var pendingSeconds;
    let recordingTimers = this.messageUITarget.querySelectorAll("#recordingIndicatorTimer");
    let stopBtns = this.messageUITarget.querySelectorAll("#stopRecordingBtn");


    if(Number.isInteger(this.timeLeftValue) && (this.timeLeftValue > 0 || this.recordingEndedValue === true)) {
      // check if record limit countdown is in progress(timeLeftValue will be 1>= value)
      // or recordingEndedValue is true when countdown time left is 1 or 0 second
      pendingSeconds = this.timeLeftValue
    } else {
      pendingSeconds = parseInt(this.pendingSecondsValue);
    }

    let non_account_member = this.nonAccountMemberValue

    if(non_account_member === "true") {
      // will execute only if current account is free account
      limitRecordingInterval = setTimeout(function () {
        // setTimeout will execute the code when 10 seconds left
        // execute the countdown when pending seconds is less than 10 secs
        this.endRecordingTimer(pendingSeconds)
      }.bind(this), (pendingSeconds - 10) * 1000)


      if(recordingTimers) {
        recordingTimers.forEach(element => {
          element.addEventListener("click", (e) => {
            clearInterval(limitRecordingInterval);
            clearInterval(window.timerInterval);
            document.title = "Recording stopped...";
          });
        });
      }

      if(stopBtns) {
        stopBtns.forEach(element => {
          element.addEventListener("click", (e) => {
            clearInterval(limitRecordingInterval);
            clearInterval(window.timerInterval);
            document.title = "Recording stopped...";
          });
        });
      }
    }
  }

  endRecordingTimer(pendingSeconds, maxMediaError = false) {
    var recordingIndicators = this.recordingIndicatorTargets;
    let timer_containers = this.timerTargets;

    recordingIndicators.forEach(element => {
      var timer_pulse = element.querySelector("#timer-pulse");
      element.classList.add("border-primary-600", "bg-white");
      timer_pulse.classList.remove("hidden");
    })
    timer_containers.forEach(element => {
      var timer_element = element.querySelector("#timer");
      timer_element.classList.add("nearing-limit")
    })


    this.timeLeftValue = (pendingSeconds > 10) ? 10 : pendingSeconds;

    clearInterval(window.timerInterval); // clear normal start timer or resume timer

    window.timerInterval = setInterval(function () {
      if(this.timeLeftValue <= 0) {
        clearInterval(window.timerInterval); // clear window.timerInterval to stop countdown
        this.stopBtnTarget.click();
        document.title = "Recording stopped...";
        this.timeLeftValue = 0;

        if(this.hasReachedLimitMessageTarget) {
          // show limit reached message at the top of preview
          this.reachedLimitMessageTarget.classList.remove("hidden");

          // Trigger a "click" of this invisible link to fire the customer.io event only for free account
          var hitRecordingLimit = this.reachedLimitMessageTarget.querySelector("#fire-hit-recording-limit-event")

          if(hitRecordingLimit) {
            var attachPrefixUrl =  hitRecordingLimit.href.includes('?') ? hitRecordingLimit.href + '&' : hitRecordingLimit.href + '?';
            this.reachedLimitMessageTarget.querySelector("#fire-hit-recording-limit-event").href = attachPrefixUrl + 'triggered_from_creation_controller=true';
            hitRecordingLimit.click();
          }


          if(maxMediaError === true) {
            this.reachedLimitMessageTarget.innerHTML = "Your recording automatically stopped because it reached the maximum file size";
          }
        }


      } else {
        timer_containers.forEach(element => {
          var timer_element = element.querySelector("#timer");
          timer_element.innerHTML = this.timeLeftValue; // show the countdown in timer html element
        })
        document.title = `${this.timeLeftValue} seconds until recording stops...`; // update the tab title
      }

      this.timeLeftValue -= 1;
      if(this.timeLeftValue === 0) {
        // set timeLeftValue when countdown reaches 0 secs
        this.recordingEndedValue = true;
      }
    }.bind(this), 1000);
  }

  // function will stop camera stream, screen stream and pipwindow
  resetDeviceSelection() {
    console.log(window.stream, window.streamArr)
    if (window.stream) {
      window.stream.getTracks().forEach(track => {
        track.stop();
      });

      if(window.streamArr) {
        window.streamArr.forEach(stream => {
          stream.getTracks().forEach(track => {
            track.stop();
          });
        })
      }
    }

    if (document.pictureInPictureElement) {
      document.exitPictureInPicture(); // close PiP window
    }
  }

  validateMessage(event){
    event.preventDefault()

    // Text - Check if the text input has value
    if(this.textEditorTarget.querySelector('input').value){
      return this.dispatchMessageValidated(event)
    }

    if(this.hasMediaFieldTarget){
      // Record - Check if the media is recorded
      var recorded_media = this.mediaFieldTarget.parentElement.querySelector('#media-signed-id')
      if(recorded_media && recorded_media.value)
        return this.dispatchMessageValidated(event)

      var multi_part_upload = this.mediaFieldTarget.parentElement.querySelector('#media-key')
      if(multi_part_upload && multi_part_upload.value)
        return this.dispatchMessageValidated(event)

      // Upload - Check if the media is uploaded
      if(this.mediaFieldTarget.files.length)
        return this.dispatchMessageValidated(event)
    }

    // Embed - Check if the embedded URL if filled
    if(this.embedIframeFieldTarget.value || this.embedPlayerLinkFieldTarget.value)
      return this.dispatchMessageValidated(event)

    // Files - Check if the file is attached
    if(this.fileAttachmentFieldTarget.dataset.filesLength > 0)
      return this.dispatchMessageValidated(event)

    var error_message = "Message doesn't have any content";
    var bottom_error_container = this.messageUITarget.querySelector('.new-message-submit-block .error-message');
    var top_error_container = this.messageUITarget.querySelector('#upload-errors');

    bottom_error_container.classList.remove("hidden");
    bottom_error_container.innerHTML = error_message;
    top_error_container.classList.remove("hidden");
    top_error_container.innerHTML = error_message;
  }

  dispatchMessageValidated(event){
    event.target.dispatchEvent(new CustomEvent("message-validated", { bubbles: true }));
  }

  clearConversationLink(event){
    this.conversationLinkFieldTarget.value = null
    this.changeBtnText()
  }

  changeBtnText(event){
    var button_text;
    var submitting_text = this.submitButtonTopTarget.dataset.disableWith;

    if (this.hasScheduledBooleanInputTarget && this.scheduledBooleanInputTarget.value == "true") {
      button_text = this.submitButtonTopTarget.dataset.scheduleMessageBtnText;
      submitting_text = this.submitButtonTopTarget.dataset.scheduleMessageSubmittingText;
    } else if(this.conversationLinkFieldTarget.value) {
      button_text = this.submitButtonTopTarget.dataset.conversationLinkBtnText
    } else {
      button_text = this.submitButtonTopTarget.dataset.btnText
    }

    this.submitButtonTopTarget.innerHTML = button_text;
    this.submitButtonBottomTarget.innerHTML = button_text;
    this.submitButtonTopTarget.dataset.disableWith = submitting_text;
    this.submitButtonBottomTarget.dataset.disableWith = submitting_text;
  }

  insertFixedControls(event){

    Rails.ajax({
      type: "get",
      url: '/insert_fixed_controls?form_id=' + event.target.closest('form').id
    })
  }

  got_to_message_creator(event){
    var form = event.target.closest('form')
    if (form) {
      form.scrollIntoView({behavior: "smooth", block: "start"});
    }
  }

  close_fixed_controls(){
    var fixed_controls = document.querySelector('#fixed-message-creation-controls')
    fixed_controls.remove()
  }
}
